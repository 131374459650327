<template>
  <div class="row mt-2 users">
    <div class="col-md-12">
      <div class="card border-0 p-2">
        <div class="row">
          <div class="col-md-12 mt-1 pt-1">
            <div class="form-group row">
              <label for="" class="col-sm-1"> File </label>
              <div class="col-sm-4">
                <input
                  type="file"
                  id="file"
                  @change="_changeFile"
                  class="form-control"
                />
              </div>
              <div class="col-sm-4">
                <button class="btn btn-sm btn-danger" @click="_actionCancel">
                  <i class="fa fa-times"></i> Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="isLoading" class="row justify-content-center mb-1">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>

          <div class="row mb-2" v-if="items.length > 0">
            <div class="col-sm-2 justify-content-start">
              <button class="btn btn-sm btn-success" @click="_actionProses">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Proses
              </button>
            </div>
          </div>
          <table class="table table-sm table-bordered table-hover">
            <thead class="text-uppercase">
              <tr>
                <th>No</th>
                <th>Email</th>
                <th>username</th>
                <th>Password</th>
                <th>Phone</th>
                <th>Gender</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-for="data in items" :key="data.id">
              <tr>
                <td>{{ parseInt(data.id) + 1 }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.username }}</td>
                <td>{{ data.password }}</td>
                <td>{{ data.phone_number }}</td>
                <td>{{ data.gender }}</td>
                <td>
                  <button
                    class="btn btn-sm btn-danger"
                    @click="DeleteData(data.id)"
                  >
                    <span class="fa fa-trash"> deleted </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import XLSX from "xlsx";
import moment from "moment";
import crypto from "crypto";
export default {
  name: "UserRegisterBulk",
  data() {
    return {
      Form: {},
      items: [],
      showModalUpload: false,
      pilih: [],
      progressbar: 0,
      showProgress: false,
      warning: false,
      nilai: 0,
      temp_items: [],
      isLoading: false
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    DeleteData(id) {
      let dataAkhir = this.items.filter(item => item.id != id);
      this.items = dataAkhir;
    },
    _actionCancel() {
      this.items = [];
      document.getElementById("file").value = "";
    },
    _formatYear(tgl) {
      return moment(tgl).format("DD-MM-YYYY");
    },
    RemoveFormatCurrency(params) {
      return params.replace(/[ ,.]/g, "");
    },
    _changeFile(e) {
      this.isLoading = true;
      var reader = new FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      let TableData = [];
      reader.onload = function() {
        var data = new Uint8Array(reader.result);
        var wb = XLSX.read(data, { type: "array" });
        let GetSheets = wb.SheetNames[0];

        /* Get worksheet */
        const worksheet = wb.Sheets[GetSheets];

        let DataRows = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        //console.table(DataRows);
        for (let index = 0; index < DataRows.length; index++) {
          console.log(DataRows[index]);
          TableData.push({
            id: index,
            email: DataRows[index].EMAIL,
            username: DataRows[index].USERNAME,
            password: DataRows[index].PASSWORD,
            phone_number: DataRows[index].PHONE,
            gender: DataRows[index].GENDER
          });
        }
      };

      //this.temp_items = TableData;

      setTimeout(() => {
        this.cleanData(TableData);
      }, 1000);

      // this.items = cleanData
    },
    cleanData(items) {
      this.isLoading = false;
      let cleanData = [];
      items.forEach(element => {
        cleanData.push({
          id: element.id,
          email: element.email,
          username: element.username,
          first_name: element.email,
          last_name: element.email,
          password: element.password,
          phone_number: element.phone_number,
          gender: element.gender
        });
      });

      this.items = cleanData;
    },
    _actionProses() {
      this.$swal({
        title: "Apakah Anda Ayakin ? ",
        text:
          "Semua Data ini Akan Di Proses Untuk Di Tambahakan Ke Data Customers",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya!"
      }).then(result => {
        if (result.value) {
          this.isLoading = true;
          this.items.forEach(u => {
            let username = u.email;

            let payload = {
              email: u.email,
              username: username.split("@")[0],
              first_name: username.split("@")[0],
              last_name: username.split("@")[1],
              phone_number: "+" + u.phone_number,
              password: this.hashPassword(u.password),
              origin_client_id: 1,
              is_active: true,
              is_verified: false,
              is_display_point: true,
              allow_age_restricted_content: false,
              profile: {
                birthdate: moment("1990-01-01").format("YYYY-MM-DD hh:mm:ss"),
                gender: u.gender == "P" ? "1" : "0"
              }
            };
            // console.table(payload);
            let BaseUrl = `https://dev.apps-foundry.com/scoopcor/api/v1/users`;
            this.$axios
              .post(BaseUrl, payload, {
                header: { "User-Agent": "SCOOP WEB/5.5.1" }
              })
              .then(response => {
                this.$swal({
                  type: "success",
                  text: response.data.message,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                });
                this.isLoading = false;
              })
              .catch(err => {
                this.isLoading = false;

                this.$swal({
                  type: "error",
                  text: err.response.data,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                });
              });
          });
        }
      });
    },
    hashPassword(password) {
      let salt = "5gvi-ojhgf%^&YGBNeds1{";
      return crypto
        .createHash("sha1")
        .update(password + salt)
        .digest("hex");
    }
  }
};
</script>
<style scoped>
.users {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
