var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-2 users"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0 p-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 mt-1 pt-1"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-1",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" File ")]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "file",
      "id": "file"
    },
    on: {
      "change": _vm._changeFile
    }
  })]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-danger",
    on: {
      "click": _vm._actionCancel
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  }), _vm._v(" Cancel ")])])])])]), _c('div', {
    staticClass: "card-body"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "row justify-content-center mb-1"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Spinning"
    }
  })], 1) : _vm._e(), _vm.items.length > 0 ? _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col-sm-2 justify-content-start"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-success",
    on: {
      "click": _vm._actionProses
    }
  }, [_c('i', {
    staticClass: "fa fa-cloud-upload",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Proses ")])])]) : _vm._e(), _c('table', {
    staticClass: "table table-sm table-bordered table-hover"
  }, [_vm._m(0), _vm._l(_vm.items, function (data) {
    return _c('tbody', {
      key: data.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(parseInt(data.id) + 1))]), _c('td', [_vm._v(_vm._s(data.email))]), _c('td', [_vm._v(_vm._s(data.username))]), _c('td', [_vm._v(_vm._s(data.password))]), _c('td', [_vm._v(_vm._s(data.phone_number))]), _c('td', [_vm._v(_vm._s(data.gender))]), _c('td', [_c('button', {
      staticClass: "btn btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.DeleteData(data.id);
        }
      }
    }, [_c('span', {
      staticClass: "fa fa-trash"
    }, [_vm._v(" deleted ")])])])])]);
  })], 2)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "text-uppercase"
  }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Email")]), _c('th', [_vm._v("username")]), _c('th', [_vm._v("Password")]), _c('th', [_vm._v("Phone")]), _c('th', [_vm._v("Gender")]), _c('th', [_vm._v("Action")])])]);
}]

export { render, staticRenderFns }